<template>
  <!-- AI智能助手弹窗 -->
  <div class="pop-ai" v-if="ai_is_show">
    <div class="meetMod" ref="scrollableDiv">
      <div class="item-ai">
        <img src="/static/images/img-k.svg" alt="ikeyhub平台的小K">
        <div class="cont">
          <div class="name">AI智能助手-小K</div>
          <div class="txt">
            <p>你好，我是ikeyhub智能助理小K！</p>
            <p>•我在这里帮助处理各行业的解决方案，海量行业研究报告和行业解决方案供您参考。请告诉我你需要查询的行业报告关键词及需求。</p>
          </div>
        </div>
      </div>
      <template v-for="(msg,index) in messageList" v-bind:key="index">
        <div v-if="msg.role === 'user'" class="item-me">
          <span>{{ msg.content }}</span>
        </div>
        <div v-if="msg.role === 'assistant'" class="item-ai">
          <img src="/static/images/img-k.svg" alt="ikeyhub 小K">
          <div class="cont">
            <div class="name">AI智能助手-小K</div>
            <div class="txt">
              <p>{{ msg.content }}</p>
              <!--<div class="file-mod">
                <img src="/static/images/img-pdf.png" alt="">
                <div class="file-info">
                  <div class="file-tit">智慧农业方案/ikeyhub.pdf</div>
                  <div class="file-num">2.5M</div>
                </div>
              </div>-->
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="input-mod" @keyup.enter.down="keyEnter">
      <el-input type="textarea" placeholder="在此输入您想了解的内容，小K会帮您解答..." resize="none" :rows="4" v-model="userQuery"></el-input>
      <div class="input-btns">
        <div class="btn-send" @click="baiLianQueryAsk">发送</div>
      </div>
    </div>
    <div class="tips">*内容由ikeyhub咋办大模型生成；生成的所有内容都是由人工智能模型生成，我们对其生成内容的准确性、完整性和功能性不做任何保证，且其生成的内容不代表我们的态度或观点。</div>
    <div class="btn-close" @click="closeFrom">
      <img src="static/images/icon-close.png">
    </div>
  </div>
</template>

<script>
import BaiLianAi from "@/api/bailianai";
import {mapState} from "vuex";

export default {
  name: "bai_lian",
  computed: {
    ...mapState(['account'])
  },
  props:{
    ai_is_show:{
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      messageList:[],
      userQuery: '',
    }
  },
  created() {
    //this.baiLianQueryAsk();//百炼 请求PHP接口
  },
  mounted() {
  },
  watch: {
    ai_is_show:{
      handler(e) {
       if(e){
         this.$nextTick(()=>{ // 对话内容滚动条滚动至最底部
           this.$refs.scrollableDiv.scrollTop = this.$refs.scrollableDiv.scrollHeight
         })
       }
      },
      immediate: true
    }
  },
  methods: {
    isParamEmpty(param) {
      return (
          param == null || // 检查null或undefined
          param == '' //|| // 检查空字符串
         //(Array.isArray(param) && param.length === 0) || // 检查空数组
          //(typeof param === 'object' && Object.keys(param).length === 0) // 检查空对象
      );
    },
    //enter键提交
    keyEnter(e) {
      //console.log(e.keyCode);
      if (e.keyCode == 13 || e.keyCode == 100) {
        this.baiLianQueryAsk();
      }
    },
    //百炼发送
    async baiLianQueryAsk(){
      if(!this.userQuery.trim().length){
        this.$message.error("您输入的内容不可为空！");
        this.userQuery='';
        return false;
      }
      const query = {type:'text',role:'user',content:this.userQuery};
      this.userQuery='';
      this.messageList = [...this.messageList, query,{content: "小k正在为您解答，请稍等片刻...\n",role: "assistant",type: "text"}];
      this.$nextTick(()=>{ // 对话内容滚动条滚动至最底部
        this.$refs.scrollableDiv.scrollTop = this.$refs.scrollableDiv.scrollHeight
      })
      await BaiLianAi.baiLianQueryAsk(query).then(res => {
        const message = res.data.choices[0].message;
        //console.log('this-:',message);
        const assistantAnswer = {type:'text',role:message.role,content:message.content};
        // this.messageList = [...this.messageList, assistantAnswer];
        this.messageList[this.messageList.length - 1] = assistantAnswer

        this.$nextTick(()=>{ // 对话内容滚动条滚动至最底部
          this.$refs.scrollableDiv.scrollTop = this.$refs.scrollableDiv.scrollHeight
        })
      })
    },
    //调用百炼测试
    async baiLianQuery() {
      try {
        // 发送查询到通义千问服务器
        const response = await this.sendQueryToYQBot(this.userQuery);
        // 处理响应，展示结果
        const answer = response.data.answer;
        console.log(answer);
      } catch (error) {
        console.error('Error querying YQBot:', error);
        const answer = 'Sorry, an error occurred while querying the bot.';
        console.log(answer);
      }
    },
    async sendQueryToYQBot(query) {
      // 替换为实际的通义千问服务器地址
      const yqbotUrl = 'http://your-yqbot-server.com/api/ask';
      const response = await this.$http.get(yqbotUrl, {
        params: {
          q: query
        }
      });
      return response;
    },
    //关闭百炼AI窗口
    closeFrom() {
      this.$store.dispatch('toggleBaiLianModal');
    }
  }
}
</script>
<style lang="scss" scoped>
.pop-ai{position: fixed;right:0;top:110px;z-index:999;width:660px;padding:20px;background: #fff;border-left:2px solid #C2F41F;box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.1);  
  .meetMod{height:calc(100vh - 530px);overflow-y:auto;}
  .item-ai{display: flex;align-items: flex-start;margin-bottom:20px;
    img{width:60px;height: 60px;margin-right:10px;}
    .cont{
      .name{color: #000000;font-size: 16px;height:30px;line-height:35px;}
      .txt{position: relative; width:515px;padding:0 36px 0 19px;margin-top:20px;background:url('/static/images/ai-chat-bg00.png') -15px 0 repeat-y;background-size:100% 100%;font-family: Source Han Sans;color: #333333;font-size: 16px;line-height: 28px;
        .file-mod{display: flex;align-items: center;margin-top:15px;
          img{width:60px;height: 60px;margin-right:10px;}
          .file-info{font-family: Source Han Sans;
            .file-tit{color: #333333;font-size: 18px;line-height: 20px;margin-bottom:5px;}
            .file-num{color: #666666;font-size: 16px;}
          }
        }
        &::before{position:absolute;top:-18px;left:0;content:'';width:100%;height:20px;background:url('/static/images/ai-chat-bg01.png') -17px 0 no-repeat;background-size:100% 100%;}
        &::after{position:absolute;bottom:-18px;left:0;content:'';width:100%;height:20px;background:url('/static/images/ai-chat-bg02.png') -15px 0 no-repeat;background-size:100% 100%;}
    }
    }
  }
  .item-me{display:flex;justify-content:flex-end; 
    span{padding:10px 30px;background: #C2F41F;color: #333333;line-height: 28px;font-size: 16px;}
  }
  .input-mod{background: #FFFFFF;box-sizing: border-box;border: 1px solid #000000;height: 180px;margin-top:60px;margin-bottom:30px; border-radius: 8px;padding:10px 10px 20px;
    :deep(.el-textarea__inner){box-shadow: none;font-size: 16px;}
    .input-btns{display: flex;justify-content: flex-end;margin-top:8px;
      .btn-send{width: 88px;height: 40px;line-height:40px;text-align:center;border-radius: 6px;background: #C1F421;cursor:pointer;color: #000000;font-size: 18px;}
    }
  }
  .tips{color: #999999;font-size: 14px;line-height: 16px;text-align: center;}
  .btn-close{position:absolute;top:-8px;left:-34px;cursor: pointer;
    img{display: block;width:34px;height:34px;}
  }
}
</style>

/*无需权限数据接口*/
import {req} from '../utils/request';

export class BaiLianAi {

  /**
   * 百炼问答
   * @param params
   * @returns {Promise<FlyResponse<any>>}
   */
  baiLianQueryAsk(params){
    return req.post('user.BailianAi/queryAsk', params);
  }

}
export default new BaiLianAi();

<template>
    <el-config-provider :locale="locale">
<!--      <router-view :key="$route.fullPath"></router-view>-->

      <!-- AI智能助手弹窗 -->
      <BaiLian :ai_is_show="showBaiLianModal"></BaiLian>
      <router-view></router-view>
      <!-- 全局登录弹窗 -->
      <login_modal :is-visible="showLoginModal"></login_modal>
    </el-config-provider>
</template>

<script>
import {ElConfigProvider} from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import login_modal from "./components/login_modal";
import {mapState} from 'vuex'
import BaiLian from "@/components/bai_lian.vue";

export default {
  name: 'App',
  components: {BaiLian, login_modal, ElConfigProvider},
  computed: {
    ...mapState(['showLoginModal','showBaiLianModal'])
  },
  data() {
    return {
      locale: zhCn,
    }
  },
  mounted() {

  },
  methods: {
    //登录弹框
    showLogin() {
      this.$store.dispatch('toggleLoginModal');
    }

  }
}
</script>

<style lang="scss">
@import "assets/css/reset.scss";
@import "assets/css/admin.scss";
//引入图标库及通用样式
@import 'assets/iconfont/iconfont.css';
@import 'assets/css/common.scss';
.this-box{
  height: 100%;  // 不必是100vh，只需要是该容器显示的最大高度即可
  overflow-x: hidden;
}
</style>
